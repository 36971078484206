<template>
  <div class="big_box">
    <div style="margin: 0 auto;max-width: 1800px;">
      <div class="game_box">
        <a :href="'/P/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')" class="item　sc-wr3rvk-0" @click="iconClick(item)" v-for="(item,index) in gameList" :key="index">
          <div class="item_position">
            <img v-lazy="item.iconUrl" alt="">
          </div>
        </a>
        <div class="right_adv adv">
          <div class="iFmTCx">
            <div class="eDhvMX" style="height: 250px; width: 315px;overflow: hidden;">
              <ins v-if="!noAdvertisements" class="adsbygoogle"
                   style="display:block;width: auto;height: 250px"
                   :data-ad-client="caPub"
                   :data-ad-slot="rightSlot"
                   data-ad-format="true"
                   data-full-width-responsive="true"></ins>
            </div>
            <div class="iZJgLq">Advertisement</div>
          </div>
        </div>
        <div class="bottom_adv adv">
          <div class="iFmTCx">
            <div class="eDhvMX" style="height: 90px; width: 728px;overflow: hidden;">
              <ins v-if="!noAdvertisements" class="adsbygoogle"
                   style="display:block;height: 90px; width: 728px;"
                   :data-ad-client="caPub"
                   :data-ad-slot="bottomSlot"
                   data-ad-format="true"
                   data-full-width-responsive="true"></ins>
            </div>
            <div class="iZJgLq">Advertisement</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {iconClickEscalation, recentGame, shuffle} from '@/utils/utils.js'
export default {
  name: "contentIndex",
  data() {
    return {
      gameList: [], // 游戏列表
      caPub: null,
      rightSlot: null,
      bottomSlot: null,
      noAdvertisements: null,
    }
  },
  created() {
    const { query } = this.$route
    const { gameType } = query || {}
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { channel_ca_pub, p_home_right, p_home_bottom, p_type_right, p_type_bottom, noAdvertisements } = channelInfo || {}
    this.caPub = channel_ca_pub
    this.rightSlot = gameType ? p_type_right : p_home_right
    this.bottomSlot = gameType ? p_type_bottom : p_home_bottom
    this.noAdvertisements = noAdvertisements
  },
  mounted() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { noAdvertisements } = channelInfo || {}
    this.getJson()
    if (!noAdvertisements) {
      setTimeout(()=>{
        window.addAds()
      },800)
    }
  },
  methods: {
    getJson() {
      const { query } = this.$route
      const { gameType } = query || {}
      let arr = []
      if (gameType) {
        let innerWidth = window.innerWidth
        let arr1 = shuffle(getAllJson()) || []
        window.onresize = () => {
          let innerWidth1 = window.innerWidth
          this.pushItem(innerWidth1,arr1,gameType)
        }
        this.pushItem(innerWidth,arr1,gameType)
      } else {
        arr = getAllJson()
        this.gameList = arr
      }
    },
    pushItem(innerWidth,arr1,gameType) {
      let arr = []
      getAllJson() && getAllJson().map((item)=>{
        if (item[gameType] == 1) {
          arr.push(item)
        }
      })
      if (!arr.length) {
        let newArr = []
        getAllJson() && getAllJson().map((item)=>{
          newArr.push(item)
        })
        arr = shuffle(newArr).splice(0, 30)
      }
      if (innerWidth >= 1800) {
        arr1.map((item)=>{
          if (arr.length < 20) {
            arr.push(item)
          }
        })
      }
      if (innerWidth >= 1652 && innerWidth <= 1799.9) {
        console.log(arr.length);
        arr1.map((item)=>{
          if (arr.length < 18) {
            arr.push(item)
          }
        })
      }
      if (innerWidth >= 1487 && innerWidth <= 1651.9) {
        console.log(arr.length);
        arr1.map((item)=>{
          if (arr.length < 16) {
            arr.push(item)
          }
        })
      }
      if (innerWidth >= 1322 && innerWidth <= 1486.9) {
        console.log(arr.length);
        arr1.map((item)=>{
          if (arr.length < 13) {
            arr.push(item)
          }
        })
      }
      this.gameList = arr
    },
    // 点击跳转详情
    iconClick(item) {
      iconClickEscalation()
      recentGame(item)
    }
  },
  watch: {
    '$route'(val) {
      console.log(val,'数据更新了');
      this.getJson()
      // this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
  .game_box{
    display: grid;
    grid-template-rows: repeat(auto-fill,150px);
    grid-template-columns: repeat(auto-fill,150px);
    grid-gap: 15px;
    grid-auto-flow: dense;
    justify-content: center;
    padding: 10px 0;
    box-sizing: border-box;
    @keyframes example {
      0%  {transform: scale(1);}
      35%  {transform: scale(1.2);}
      65% {transform: scale(1.1);}
      100% {transform: scale(1.3);}
    }
    .item:hover{
      cursor: pointer;
      animation-name: example;
      animation-duration: 0.6s;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-direction: normal;
      animation-fill-mode: forwards;
      animation-play-state: running;
      .item_position{
        z-index: 23;
      }
    }

    .item{
      box-sizing: border-box;
      width: 150px;
      height: 150px;
      .item_position{
        padding-top: 100%;
        width: 100%;
        position: relative;
        height: 0;
        z-index: -1;
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 20px;
          background: white;
        }
      }
    }
    .right_adv,.bottom_adv{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      .iFmTCx {
        display: inline-flex;
        flex-direction: column;
        .eDhvMX {
          background: rgba(255, 255, 255, 0.5);
          border-radius: 10px;
        }

        .iZJgLq {
          font: 400 9px / 2 Arial, sans-serif;
          //text-transform: uppercase;
          text-align: center;
          opacity: 0.7;
          height: 20px;
          letter-spacing: 1px;
          color: #ffffff;
        }
      }
    }
  }
  .game_box:after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
  }
  @media screen and (min-width: 1800px){
    .right_adv{
      grid-column-start: 10;
      grid-column-end: 12;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .bottom_adv{
      grid-column-start: 3;
      grid-column-end: 8;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }
  @media screen and (min-width: 1652px) and (max-width: 1799.9px){
    .right_adv{
      grid-column-start: 9;
      grid-column-end: 11;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .bottom_adv{
      grid-column-start: 3;
      grid-column-end: 8;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }
  @media screen and (min-width: 1487px) and (max-width: 1651.9px){
    .right_adv{
      grid-column-start: 8;
      grid-column-end: 10;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .bottom_adv{
      grid-column-start: 3;
      grid-column-end: 8;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }
  @media screen and (min-width: 1322px) and (max-width: 1486.9px){
    .right_adv{
      grid-column-start: 7;
      grid-column-end: 9;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .bottom_adv{
      grid-column-start: 2;
      grid-column-end: 7;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }
  @media screen and (min-width: 1157px) and (max-width: 1321.9px){
    .right_adv{
      grid-column-start: 6;
      grid-column-end: 8;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .bottom_adv{
      grid-column-start: 2;
      grid-column-end: 7;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1156.9px){
    .right_adv{
      grid-column-start: 5;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .bottom_adv{
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }
  @media screen and (max-width: 1024px){
    .right_adv{
      grid-column-start: 5;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .bottom_adv{
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }
</style>
