<template>
  <div class="class-box" :style="styleType ? {marginTop: 0} : null" v-if="from == 1">
    <div class="class-list" :style="styleType ? {padding: 0} : null" v-if="gameTypeList.length > 9">
      <div class="class-item　sc-wr3rvk-0" @click="detailsClick(item)" v-for="(item,index) in topList" :key="index">
        <a :href="'/M/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item.iconUrl" alt=""></a>
      </div>
      <div v-if="from == 1" class="adv" style="clear: both; margin-bottom: 0.5625rem">
        <ins v-if="!noAdvertisements" class="adsbygoogle"
             style="display:block"
             :data-ad-client="caPub"
             :data-ad-slot="typeSlot"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>
        <div v-if="$originCopyWriting == 'marvelplay'" :id="gptId" ref="advRef" style="text-align: center"></div>
      </div>
      <div>
        <div class="class-item　sc-wr3rvk-0" @click="detailsClick(item)" v-for="(item,index) in bottomList" :key="index + 9">
          <a :href="'/M/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item.iconUrl" alt=""></a>
        </div>
      </div>
    </div>
    <div class="class-list" :style="styleType ? {padding: 0} : null" v-else>
      <div class="class-item　sc-wr3rvk-0" @click="detailsClick(item)" v-for="(item,index) in gameTypeList" :key="index">
        <a :href="'/M/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item.iconUrl" alt=""></a>
      </div>
      <div v-if="from == 1" class="adv" style="clear: both">
        <ins v-if="!noAdvertisements" class="adsbygoogle"
             style="display:block"
             :data-ad-client="caPub"
             :data-ad-slot="typeSlot"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>
        <div v-if="$originCopyWriting == 'marvelplay'" :id="gptId" ref="advRef" style="text-align: center"></div>
      </div>
    </div>
  </div>
  <div class="class-box" :style="styleType ? {marginTop: 0} : null" v-else>
    <div class="class-list" :style="styleType ? {padding: 0} : null">
      <div class="class-item　sc-wr3rvk-0" @click="detailsClick(item)" v-for="(item,index) in gameTypeList" :key="index">
        <a :href="'/M/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item.iconUrl" alt=""></a>
      </div>
    </div>
  </div>
</template>

<script>
import {iconClickEscalation, recentGame} from '@/utils/utils.js';
export default {
  name: "ClassList",
  props: ["styleType",'gameTypeList','from','topList','bottomList'], // from 1 // 游戏类型引用
  data() {
    return {
      caPub: null,
      typeSlot: null,
      noAdvertisements: null,
      noAfc: null,
      gptId: null,
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { channel_ca_pub, m_type_slot, noAdvertisements, noAfc, m_type_gpt } = channelInfo || {}
    this.caPub = channel_ca_pub
    this.typeSlot = m_type_slot
    this.noAdvertisements = noAdvertisements
    this.noAfc = noAfc
    let gpt = m_type_gpt && m_type_gpt.split(",") || []
    this.gptId = gpt[3]
  },
  mounted() {
    if (this.from == 1) {
      let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
      const { noAdvertisements, noAfc } = channelInfo || {}
      if (!noAdvertisements) {
        setTimeout(()=>{
          window.addAds()
        },1300)
      }
      if (this.$originCopyWriting == 'marvelplay') {
        console.log(window.isDisplay);
        if (window.isDisplay) {
          googletag.cmd.push(function() { googletag.pubads().refresh(); });
        }

        let leftAdv = document.createElement("script")
        leftAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(mType[3]);window.isDisplay = true};});"
        setTimeout(()=>{
          this.$refs.advRef.append(leftAdv)
        },600)
      }
    }
  },
  methods: {
    detailsClick(item) {
      iconClickEscalation()
      recentGame(item)
      // this.$router.push({
      //   path: '/M/details',
      //   query: {
      //     gameId: item.gameId
      //   }
      // },()=>{})
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.class-box{
  margin-top: 3.7375rem;
  .class-list{
    padding: 0 0.625rem;
    .class-item{
      float: left;
      width: 5.875rem;
      height: 5.875rem;
      margin-bottom: 0.5625rem;
      background-color: #fff;
      border-radius: 0.875rem;
      box-shadow: 0 0.125rem 0.375rem 0 rgb(0 0 0/60%);
      img{
        width: 100%;
        height: 100%;
        border-radius: 0.875rem;
        border: 2px solid #fff;
        background-color: #fff;
        color: #fff;
        overflow: hidden;
      }
    }
    .class-item:not(:nth-child(3n)){
      margin-right: 0.561rem;
    }
  }
  .class-list:after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
</style>
